import { adminTypes } from "../../type/adminTypes";

const initialState = {
  isLoading: false,
  profileData: {},
  msg: "",
};
export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_PROFILE_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case adminTypes.GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        profileData: action.payload,
      };
    case adminTypes.GET_PROFILE_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export const adminTypes = {
  // Manage Dashboard

  GET_ADMIN_DASHBOARD_DATA_SUCCESS: "GET_ADMIN_DASHBOARD_DATA_SUCCESS",
  GET_ADMIN_DASHBOARD_DATA_PENDING: "GET_ADMIN_DASHBOARD_DATA_PENDING",
  GET_ADMIN_DASHBOARD_DATA_FAILED: "GET_ADMIN_DASHBOARD_DATA_FAILED",

  // profile data
  GET_PROFILE_DATA_PENDING: "GET_PROFILE_DATA_PENDING",
  GET_PROFILE_DATA_SUCCESS: "GET_PROFILE_DATA_SUCCESS",
  GET_PROFILE_DATA_FAILED: "GET_PROFILE_DATA_FAILED",

  // User data
  GET_USER_DATA_PENDING: "GET_USER_DATA_PENDING",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILED: "GET_USER_DATA_FAILED",

  GET_USER_DETAIL_PENDING: "GET_USER_DETAIL_PENDING",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILED: "GET_USER_DETAIL_FAILED",

  // Blog data
  GET_BLOG_DATA_PENDING: "GET_BLOG_DATA_PENDING",
  GET_BLOG_DATA_SUCCESS: "GET_BLOG_DATA_SUCCESS",
  GET_BLOG_DATA_FAILED: "GET_BLOG_DATA_FAILED",

  // Blog Detail data
  GET_BLOG_DETAIL_PENDING: "GET_BLOG_DETAIL_PENDING",
  GET_BLOG_DETAIL_SUCCESS: "GET_BLOG_DETAIL_SUCCESS",
  GET_BLOG_DETAIL_FAILED: "GET_BLOG_DETAIL_FAILED",

  // Category data
  GET_CATEGORY_DATA_PENDING: "GET_CATEGORY_DATA_PENDING",
  GET_CATEGORY_DATA_SUCCESS: "GET_CATEGORY_DATA_SUCCESS",
  GET_CATEGORY_DATA_FAILED: "GET_CATEGORY_DATA_FAILED",

  //Category Page data
  GET_CATEGORY_DETAIL_PENDING: "GET_CATEGORY_DETAIL_PENDING",
  GET_CATEGORY_DETAIL_SUCCESS: "GET_CATEGORY_DETAIL_SUCCESS",
  GET_CATEGORY_DETAIL_FAILED: "GET_CATEGORY_DETAIL_FAILED",

  // TESTIMONIALS data
  GET_TESTIMONIALS_DATA_PENDING: "GET_TESTIMONIALS_DATA_PENDING",
  GET_TESTIMONIALS_DATA_SUCCESS: "GET_TESTIMONIALS_DATA_SUCCESS",
  GET_TESTIMONIALS_DATA_FAILED: "GET_TESTIMONIALS_DATA_FAILED",

  // TESTIMONIALS Detail data
  GET_TESTIMONIALS_DETAIL_PENDING: "GET_TESTIMONIALS_DETAIL_PENDING",
  GET_TESTIMONIALS_DETAIL_SUCCESS: "GET_TESTIMONIALS_DETAIL_SUCCESS",
  GET_TESTIMONIALS_DETAIL_FAILED: "GET_TESTIMONIALS_DETAIL_FAILED",

  // LOAN data
  GET_LOAN_DATA_PENDING: "GET_LOAN_DATA_PENDING",
  GET_LOAN_DATA_SUCCESS: "GET_LOAN_DATA_SUCCESS",
  GET_LOAN_DATA_FAILED: "GET_LOAN_DATA_FAILED",

  // LOAN Detail data
  GET_LOAN_DETAIL_PENDING: "GET_LOAN_DETAIL_PENDING",
  GET_LOAN_DETAIL_SUCCESS: "GET_LOAN_DETAIL_SUCCESS",
  GET_LOAN_DETAIL_FAILED: "GET_LOAN_DETAIL_FAILED",

  // LOAN data
  GET_LOAN_LIST_DETAIL_PENDING: "GET_LOAN_LIST_DETAIL_PENDING",
  GET_LOAN_LIST_DETAIL_SUCCESS: "GET_LOAN_LIST_DETAIL_SUCCESS",
  GET_LOAN_LIST_DETAIL_FAILED: "GET_LOAN_LIST_DETAIL_FAILED",

  // LOAN data
  GET_LOAN_LIST_DATA_PENDING: "GET_LOAN_LIST_DATA_PENDING",
  GET_LOAN_LIST_DATA_SUCCESS: "GET_LOAN_LIST_DATA_SUCCESS",
  GET_LOAN_LIST_DATA_FAILED: "GET_LOAN_LIST_DATA_FAILED",

  // LOAN Detail data
  GET_PERSONAL_DETAIL_PENDING: "GET_PERSONAL_DETAIL_PENDING",
  GET_PERSONAL_DETAIL_SUCCESS: "GET_PERSONAL_DETAIL_SUCCESS",
  GET_PERSONAL_DETAIL_FAILED: "GET_PERSONAL_DETAIL_FAILED",

  // LOAN data
  GET_PERSONAL_LIST_DATA_PENDING: "GET_PERSONAL_LIST_DATA_PENDING",
  GET_PERSONAL_LIST_DATA_SUCCESS: "GET_PERSONAL_LIST_DATA_SUCCESS",
  GET_PERSONAL_LIST_DATA_FAILED: "GET_PERSONAL_LIST_DATA_FAILED",

  // LOAN Detail data
  GET_BUSINESS_DETAIL_PENDING: "GET_BUSINESS_DETAIL_PENDING",
  GET_BUSINESS_DETAIL_SUCCESS: "GET_BUSINESS_DETAIL_SUCCESS",
  GET_BUSINESS_DETAIL_FAILED: "GET_BUSINESS_DETAIL_FAILED",

  // LOAN data
  GET_BUSINESS_LIST_DATA_PENDING: "GET_BUSINESS_LIST_DATA_PENDING",
  GET_BUSINESS_LIST_DATA_SUCCESS: "GET_BUSINESS_LIST_DATA_SUCCESS",
  GET_BUSINESS_LIST_DATA_FAILED: "GET_BUSINESS_LIST_DATA_FAILED",

  // Managed Loan Detail data
  GET_MANAGED_LOAN_DETAIL_PENDING: "GET_MANAGED_LOAN_DETAIL_PENDING",
  GET_MANAGED_LOAN_DETAIL_SUCCESS: "GET_MANAGED_LOAN_DETAIL_SUCCESS",
  GET_MANAGED_LOAN_DETAIL_FAILED: "GET_MANAGED_LOAN_DETAIL_FAILED",

  // Managed LOAN data
  GET_MANAGED_LOAN_LIST_DATA_PENDING: "GET_BUSINESS_LIST_DATA_PENDING",
  GET_MANAGED_LOAN_LIST_DATA_SUCCESS: "GET_BUSINESS_LIST_DATA_SUCCESS",
  GET_MANAGED_LOAN_LIST_DATA_FAILED: "GET_MANAGED_LOAN_LIST_DATA_FAILED",

  //Banner
  GET_BANNER_DETAIL_PENDING: "GET_BANNER_DETAIL_PENDING",
  GET_BANNER_DETAIL_SUCCESS: "GET_BANNER_DETAIL_SUCCESS",
  GET_BANNER_DETAIL_FAILED: "GET_BANNER_DETAIL_FAILED",

  //Banner
  GET_BANNER_LIST_PENDING: "GET_BANNER_LIST_PENDING",
  GET_BANNER_LIST_SUCCESS: "GET_BANNER_LIST_SUCCESS",
  GET_BANNER_LIST_FAILED: "GET_BANNER_LIST_FAILED",

  //Subscriber
  GET_SUBSCRIBER_LIST_PENDING: "GET_SUBSCRIBER_LIST_PENDING",
  GET_SUBSCRIBER_LIST_SUCCESS: "GET_SUBSCRIBER_LIST_SUCCESS",
  GET_SUBSCRIBER_LIST_FAILED: "GET_SUBSCRIBER_LIST_FAILED",
  //User
  GET_USER_DATAS_PENDING: "GET_USER_DATAS_PENDING",
  GET_USER_DATAS_SUCCESS: "GET_USER_DATAS_SUCCESS",
  GET_USER_DATAS_FAILED: "GET_USER_DATAS_FAILED",

  GET_USER_LIST_PENDING: "GET_USER_LIST_PENDING",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILED: "GET_USER_LIST_FAILED",

  GET_LOAN_CATEGORY_LIST_PENDING: "GET_LOAN_CATEGORY_LIST_PENDING",
  GET_LOAN_CATEGORY_LIST_SUCCESS: "GET_LOAN_CATEGORY_LIST_SUCCESS",
  GET_LOAN_CATEGORY_LIST_FAILED: "GET_LOAN_CATEGORY_LIST_FAILED",

  GET_DASHBOARD_DATA_PENDING: "GET_DASHBOARD_DATA_PENDING",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_DATA_FAILED: "GET_DASHBOARD_DATA_FAILED",

  GET_POPUP_DATA_PENDING: "GET_POPUP_DATA_PENDING",
  GET_POPUP_DATA_SUCCESS: "GET_POPUP_DATA_SUCCESS",
  GET_POPUP_DATA_FAILED: "GET_POPUP_DATA_FAILED",

  GET_POPUP_DETAILS_PENDING: "GET_POPUP_DETAILS_PENDING",
  GET_POPUP_DETAILS_SUCCESS: "GET_POPUP_DETAILS_SUCCESS",
  GET_POPUP_DETAILS_FAILED: "GET_POPUP_DETAILS_FAILED",

  GET_LOAN_CATEGORY_FILTER_LIST_PENDING:
    "GET_LOAN_CATEGORY_FILTER_LIST_PENDING",
  GET_LOAN_CATEGORY_FILTER_LIST_SUCCESS:
    "GET_LOAN_CATEGORY_FILTER_LIST_SUCCESS",
  GET_LOAN_CATEGORY_FILTER_LIST_FAILED: "GET_LOAN_CATEGORY_FILTER_LIST_FAILED",

  GET_CSV_FILE_PENDING: "GET_CSV_FILE_PENDING",
  GET_CSV_FILE_SUCCESS: "GET_CSV_FILE_SUCCESS",
  GET_CSV_FILE_FAILED: "GET_CSV_FILE_FAILED",

  GET_CRIF_LIST_PENDING: "GET_CRIF_LIST_PENDING",
  GET_CRIF_LIST_SUCCESS: "GET_CRIF_LIST_SUCCESS",
  GET_CRIF_LIST_FAILED: "GET_CRIF_LIST_FAILED",

  GET_CRIF_DATA_PENDING: "GET_CRIF_DATA_PENDING",
  GET_CRIF_DATA_SUCCESS: "GET_CRIF_DATA_SUCCESS",
  GET_CRIF_DATA_FAILED: "GET_CRIF_DATA_FAILED",

  GENERATE_CRIF_DATA_PENDING: "GENERATE_CRIF_DATA_PENDING",
  GENERATE_CRIF_DATA_SUCCESS: "GENERATE_CRIF_DATA_SUCCESS",
  GENERATE_CRIF_DATA_FAILED: "GENERATE_CRIF_DATA_FAILED",

  PATCH_CRIF_DATA_PENDING: "PATCH_CRIF_DATA_PENDING",
  PATCH_CRIF_DATA_SUCCESS: "PATCH_CRIF_DATA_SUCCESS",
  PATCH_CRIF_DATA_FAILED: "PATCH_CRIF_DATA_FAILED",

  //Downlaod Subscriber List

  GET_SUBSCRIBER_DATA_PENDING: "GET_SUBSCRIBER_DATA_PENDING",
  GET_SUBSCRIBER_DATA_SUCCESS: "GET_SUBSCRIBER_DATA_SUCCESS",
  GET_SUBSCRIBER_DATA_FAILED: "GET_SUBSCRIBER_DATA_FAILED",

};

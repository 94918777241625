import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  dashboardData: null,
  dashboardGraphData: null,
  msg: "",
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_DASHBOARD_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        dashboardData: action.payload,
        msg: "",
      };
    case adminTypes.GET_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        dashboardData: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_DASHBOARD_GRAPH_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_DASHBOARD_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        dashboardGraphData: action.payload,
        msg: "",
      };
    case adminTypes.GET_DASHBOARD_GRAPH_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        dashboardGraphData: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};

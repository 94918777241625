import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  loanList: null,
  loanDetail: null,
  msg: "",
  loanCatList: "",
  loanCatDetail: null,
  personalLoanList: "",
  personalLoanDetail: null,
  businessLoanList: "",
  businessLoanDetail: null,
  loanCategoryList: null,
  managedLoanList: null,
  managedLoanDetail : null,
};
export const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_LOAN_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_LOAN_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        loanList: action.payload,
        msg: "",
      };
    case adminTypes.GET_LOAN_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        loanList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_LOAN_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_LOAN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        loanDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_LOAN_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        loanDetail: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_LOAN_LIST_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_LOAN_LIST_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        loanCatList: action.payload,
        msg: "",
      };
    case adminTypes.GET_LOAN_LIST_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        loanCatList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_LOAN_LIST_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_LOAN_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        loanCatDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_LOAN_LIST_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        loanCatDetail: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_PERSONAL_LIST_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_PERSONAL_LIST_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        personalLoanList: action.payload,
        msg: "",
      };
    case adminTypes.GET_PERSONAL_LIST_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        personalLoanList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_PERSONAL_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_PERSONAL_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        personalLoanDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_PERSONAL_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        personalLoanDetail: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_LOAN_CATEGORY_LIST_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_LOAN_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        loanCategoryList: action.payload,
        msg: "",
      };
    case adminTypes.GET_LOAN_CATEGORY_LIST_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        loanCategoryList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_MANAGED_LOAN_LIST_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_MANAGED_LOAN_LIST_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        managedLoanList: action.payload,
        msg: "",
      };
    case adminTypes.GET_MANAGED_LOAN_LIST_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        managedLoanList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_MANAGED_LOAN_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_MANAGED_LOAN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        managedLoanDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_MANAGED_LOAN_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        managedLoanDetail: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

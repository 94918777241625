import { combineReducers } from "redux";
import { authReducer } from "./auth/authReducer";
import { profileReducer } from "./auth/profileReducer";
import { authorReducer } from "./author/authorReducer";
import loadingReducer from "./loading/loadingReducer";
import { blogReducer } from "./blog/blogReducer";
import { categoryReducer } from "./category/categoryReducer";
import { testimonialReducer } from "./testimonials/testimonialsReducer";
import { loanReducer } from "./loan/loanReducer";
import { bannerReducer } from "./banner/bannerReducer";
import { userReducer } from "./user/userReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";
import { popupReducer } from "./popup/popupReducer";
import { subscriberReducer } from "./subscriber/subscriberReducer";
import { crifReducer } from "./crif/crifReducer";
export const reducers = combineReducers({
  // auth
  auth: authReducer,
  profileReducer: profileReducer,
  blogReducer: blogReducer,
  loadingReducer: loadingReducer,
  categoryReducer: categoryReducer,
  authorReducer: authorReducer,
  testimonialReducer: testimonialReducer,
  loanReducer: loanReducer,
  bannerReducer: bannerReducer,
  userReducer: userReducer,
  dashboardReducer: dashboardReducer,
  popupReducer: popupReducer,
  subscriberReducer:subscriberReducer,
  crifReducer: crifReducer
});

import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  bannerDetails: null,
  bannerList: null,

  msg: "",
};
export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_BANNER_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_BANNER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        bannerDetails: action.payload,
        msg: "",
      };
    case adminTypes.GET_BANNER_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        bannerDetails: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_BANNER_LIST_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_BANNER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        bannerList: action.payload,
        msg: "",
      };
    case adminTypes.GET_BANNER_LIST_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        bannerList: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

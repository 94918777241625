import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { reducers } from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";

// Middleware and enhancer setup
const enhancer = compose(applyMiddleware(thunk, logger));

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const storeReducer = persistReducer(persistConfig, reducers);

const store = createStore(storeReducer, enhancer);

export const persistor = persistStore(store);

export default store;

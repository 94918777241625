import { adminTypes } from "app/redux/type/adminTypes";

const initialState = {
  isLoading: false,
  categoryList: null,
  categoryDetail: null,
  msg: "",
};
export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.GET_CATEGORY_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        categoryList: action.payload,
        msg: "",
      };
    case adminTypes.GET_CATEGORY_DATA_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        categoryList: action.payload,
        msg: action.msg,
      };
    case adminTypes.GET_CATEGORY_DETAIL_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
        msg: "",
      };
    case adminTypes.GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        categoryDetail: action.payload,
        msg: "",
      };
    case adminTypes.GET_CATEGORY_DETAIL_FAILED:
      return {
        ...state,
        isLoading: action.isLoading,
        categoryDetail: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
